<template>
  <div>
    <header-menu ref="HeaderMenu"></header-menu>
    <div class="view-content">
      <div class="banner" id="new_0">
        <div class="desc__box text_left">
          <h1 class="banner_title b_b_space font-size_60">新闻资讯</h1>
          <p class="banner_desc b_b_space font-size_20">
            实时掌握正也资讯，深耕医药营销数字化
          </p>
        </div>
      </div>
      <div
        class="l_r_space t__space"
        style="background-color: #f8f8f8"
        id="new_1"
      >
        <div class="tab_news">
          <a-tabs
            :activeKey="newsTab"
            @change="changeNews"
            :tabBarStyle="{
              borderBottom: 'none',
            }"
          >
            <a-tab-pane :key="'1'" tab="企业新闻" >
            </a-tab-pane>
            <a-tab-pane :key="'2'" tab="行业新闻" force-render>
            </a-tab-pane>
          </a-tabs>
          <a-row :gutter="23" type="flex">
            <a-col
              class="mouse-style"
              style="padding-bottom: 0.27rem"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="12"
              :xl="8"
              :xxl="8"
              v-for="(item, index) in newsList"
              :key="index"
              @click="toPage(item.path_name, item)"
            >
              <div class="news_item text_left">
                <div
                  class="product-info_item"
                  @mouseover="mouseOver(index)"
                  @mouseleave="mouseLeave(index)"
                >
                  <img
                    :src="item.picture_url"
                    style="height: 3rem"
                    :alt="item.title"
                    class="view_width-full"
                  />
                  <div
                    class="product-info__hover-animation"
                    :style="{ opacity: item.is_animation ? 1 : 0 }"
                  >
                    <div
                      class="product-info__hover-animation product-info__hover-animation_box"
                      :style="{
                        opacity: item.is_animation ? 1 : 0,
                        transform: item.is_animation
                          ? 'translateY(0%)'
                          : 'translateY(-20%)',
                      }"
                    >
                      <div
                        class="icon_box"
                        :id="'icon_box-hover_' + index"
                        @mouseover="mouseOverIcon(index)"
                        @mouseleave="mouseLeaveIcon(index)"
                      >
                        <a-icon type="double-right" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="item_main">
                  <div class="news_date font-size_20">
                    {{ item.date }}
                  </div>
                  <h3 class="news_title font-size_24">{{ item.title }}</h3>
                  <div class="news_info font-size_20">{{ item.new_desc }}</div>
                  <div class="view_more font-size_20">
                    <span>查看更多</span>
                    <div class="right-circle"></div>
                  </div>
                </div>
              </div> </a-col
          ></a-row>
          <a-pagination :pageSize="6" v-model="current" :total="totalCount" show-less-items @change="changePage()" />
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import {newsPage, newsList } from "@/api";
import moment from "moment";
import Footer from "@/components/Footer";
import HeaderMenu from "@/components/HeaderMenu";
export default {
  name: "news",
  components: { Footer, HeaderMenu },
  beforeCreate() {
    this.newsTab = this.$route.params.meta_type + "" || "1";
    this.anchor_type = this.$route.params.anchor_type || 0;
  },
  data() {
    return {
        current:1,
      newsList: [],
        totalCount:0
    };
  },
  created() {
    this.getNewsList();
    setTimeout(() => {
      this.goAnchor(`#new_${this.anchor_type}`);
    }, 200);
  },
  methods: {
    moment,
    goAnchor(selector) {
      document.documentElement.style["scroll-behavior"] = "smooth";
      document.documentElement.scrollTop = document.body.scrollTop =
        this.$el.querySelector(selector).offsetTop - 80;
      document.documentElement.style["scroll-behavior"] = "";
    },
    changeNews(e) {
      console.log("e", e);
      this.newsTab = e;
        this.current = 1;
      this.getNewsList();
    },
    getNewsList() {
      let parameter = { news_type: this.newsTab,'page':this.current };
        newsPage(parameter).then((res) => {
        if (res.status === 0) {
          console.log("res", res);
          let data = res.data.list;
           this.totalCount = res.data.totalCount;
          for (let item of data) {
            item.date = `${moment(item.create_date).format("YYYY-MM-DD")}`;
            item.path_name = "newsConsultingNewsContent";
            item.new_desc = item.content_text.replace(/<\/?[^>]*>/g, "");
          }
          this.newsList = data;
        }
      });
    },
    mouseOver(index) {
      this.$set(this.newsList[index], "is_animation", true);
    },
    mouseLeave(index) {
      this.$set(this.newsList[index], "is_animation", false);
    },
    mouseOverIcon(index) {
      document.getElementById(`icon_box-hover_${index}`).style.color = "#000";
      document.getElementById(`icon_box-hover_${index}`).style.backgroundColor =
        "#fff";
    },
    mouseLeaveIcon(index) {
      document.getElementById(`icon_box-hover_${index}`).style.color = "#fff";
      document.getElementById(`icon_box-hover_${index}`).style.backgroundColor =
        "rgba(88, 95, 105, 0.3)";
    },
      changePage(){
        this.getNewsList();
      },
    toPage(name, itemData = {}) {
      let router = {
        name,
      };
      if (name == "newsConsultingNewsContent") {
        router.params = { news_id: itemData.news_id };
      }
      this.$router.push(router);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/style/color.scss";
.footer {
  background-color: $color-lightGray;
}
.news_item {
  background-color: $color-white;
  border-radius: 0.05rem;
  height: 100%;
  .item_main {
    padding: 0.33rem 0.5rem 0.2rem 0.35rem;
    .news_date {
      color: $color-gray;
      margin-left: -3px;
    }
    .news_title {
      color: $color-darkBlue;
      word-break: break-all;
      padding-top: 0.32rem;
      min-height: 1rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .news_info {
      min-height: 0.8rem;
      line-height: 0.4rem;
      word-break: break-all;
      color: $color-gray;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .view_more {
      color: $color-darkBlue;
      padding-top: 0.1rem;
      .right-circle {
        width: 0.23rem;
        height: 0.23rem;
        display: inline-block;
        vertical-align: middle;
        margin: 9px;
      }
    }
  }
}

.banner {
  width: 100%;
  position: relative;
  background: url("~@/assets/img/png/news_banner.png");
  background-size: 100% 100%;
  height: 8rem;
  overflow: hidden;
  .desc__box {
    position: absolute;
    top: 2.95rem;
    left: 13.5%;
    .banner_title {
      color: $color-white;
    }
    .banner_desc {
      width: 3.92rem;
      padding-top: 0.2rem;
      color: $color-white;
      margin-bottom: 0;
    }
  }
}
.tab_news {
  // /deep/ .ant-tabs-bar {
  //   border-bottom: none;
  //   .ant-tabs-nav-scroll {
  //     color: $color-fixGray;
  //     font-size: 0.26rem;
  //     .ant-tabs-nav .ant-tabs-tab-active {
  //       color: $color-darkBlue;
  //       font-weight: bold;
  //     }
  //   }
  // }
}
.product-info_item:hover,
.product-info_item::after,
.product-info_item::before {
  transition: all 0.5s ease;
}
.product-info_item {
  position: relative;
  .product-info__hover-animation {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: all 0.5s ease;
    background-color: rgba(88, 95, 105, 0.3) !important;
    box-shadow: 0 5px 10px -6px $color-midleGray;
  }
  .product-info__hover-animation_box {
    height: 115%;
    .icon_box {
      width: 0.4rem;
      height: 0.4rem;
      background-color: rgba(88, 95, 105, 0.3);
      border: 1px solid $color-white;
      position: absolute;
      top: 42.5%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-white;
    }
  }
}
// @media all and (max-width: 1100px) {
//   .right-circle {
//     width: 12px !important;
//     height: 12px !important;
//   }
// }
</style>
