var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-menu',{ref:"HeaderMenu"}),_c('div',{staticClass:"view-content"},[_vm._m(0),_c('div',{staticClass:"l_r_space t__space",staticStyle:{"background-color":"#f8f8f8"},attrs:{"id":"new_1"}},[_c('div',{staticClass:"tab_news"},[_c('a-tabs',{attrs:{"activeKey":_vm.newsTab,"tabBarStyle":{
            borderBottom: 'none',
          }},on:{"change":_vm.changeNews}},[_c('a-tab-pane',{key:'1',attrs:{"tab":"企业新闻"}}),_c('a-tab-pane',{key:'2',attrs:{"tab":"行业新闻","force-render":""}})],1),_c('a-row',{attrs:{"gutter":23,"type":"flex"}},_vm._l((_vm.newsList),function(item,index){return _c('a-col',{key:index,staticClass:"mouse-style",staticStyle:{"padding-bottom":"0.27rem"},attrs:{"xs":24,"sm":12,"md":12,"lg":12,"xl":8,"xxl":8},on:{"click":function($event){return _vm.toPage(item.path_name, item)}}},[_c('div',{staticClass:"news_item text_left"},[_c('div',{staticClass:"product-info_item",on:{"mouseover":function($event){return _vm.mouseOver(index)},"mouseleave":function($event){return _vm.mouseLeave(index)}}},[_c('img',{staticClass:"view_width-full",staticStyle:{"height":"3rem"},attrs:{"src":item.picture_url,"alt":item.title}}),_c('div',{staticClass:"product-info__hover-animation",style:({ opacity: item.is_animation ? 1 : 0 })},[_c('div',{staticClass:"product-info__hover-animation product-info__hover-animation_box",style:({
                      opacity: item.is_animation ? 1 : 0,
                      transform: item.is_animation
                        ? 'translateY(0%)'
                        : 'translateY(-20%)',
                    })},[_c('div',{staticClass:"icon_box",attrs:{"id":'icon_box-hover_' + index},on:{"mouseover":function($event){return _vm.mouseOverIcon(index)},"mouseleave":function($event){return _vm.mouseLeaveIcon(index)}}},[_c('a-icon',{attrs:{"type":"double-right"}})],1)])])]),_c('div',{staticClass:"item_main"},[_c('div',{staticClass:"news_date font-size_20"},[_vm._v(" "+_vm._s(item.date)+" ")]),_c('h3',{staticClass:"news_title font-size_24"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"news_info font-size_20"},[_vm._v(_vm._s(item.new_desc))]),_c('div',{staticClass:"view_more font-size_20"},[_c('span',[_vm._v("查看更多")]),_c('div',{staticClass:"right-circle"})])])])])}),1),_c('a-pagination',{attrs:{"pageSize":6,"total":_vm.totalCount,"show-less-items":""},on:{"change":function($event){return _vm.changePage()}},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1)])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner",attrs:{"id":"new_0"}},[_c('div',{staticClass:"desc__box text_left"},[_c('h1',{staticClass:"banner_title b_b_space font-size_60"},[_vm._v("新闻资讯")]),_c('p',{staticClass:"banner_desc b_b_space font-size_20"},[_vm._v(" 实时掌握正也资讯，深耕医药营销数字化 ")])])])}]

export { render, staticRenderFns }